import { services as servicesApi }  from '@/api';

const services = {
    namespaced: true,

    state: {
        items: [],
        current: null
    },

    actions: {
        async get({ commit }, payload) {
            const response = await servicesApi.get(`organizations/${payload.organizationId}/services?location_id=${payload.locationId}&time=today`);

            if (response.status !== 200) {
                throw new Error('Server error');
            }
        
            commit('SET_ITEMS', response.data.data);
        },

        async store(context, payload) {
            let data = {data: {...payload, location: context.rootState.locations.current.id}};

            const response = await servicesApi.post(`organizations/${context.rootState.auth.user.organizations[0].id}/service-details`, data);

            if (response.status !== 201) {
                throw new Error('Server error');
            }
        }
    },

    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload;
        },

        SET_CURRENT(state, payload) {
            state.current = payload;
        },
    }
}

export default services

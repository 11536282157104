import axios from 'axios';
import store from '@/store';
import router from '@/router';

const createInstance = (baseURL) => {
  const instance = axios.create({
    baseURL
  });

  const token = localStorage.getItem('tithely:access_token');
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status == 401) {
        try {
          const token = await store.dispatch('auth/refreshToken');

          error.config.headers['Authorization'] = `Bearer ${token}`;

          return axios.request(error.config);
        } catch {
          router.push('/welcome');
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default createInstance;

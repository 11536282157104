<template>
  <ion-button
    expand="block"
    :class="[`btn-${variant}`]"
    :disabled="disabled"
    @click.stop="onClick"
    @mousedown="onMousedown"
  >
    <span class="text">{{ !loading ? '← Go back' : 'Loading...' }}</span>
  </ion-button>
</template>

<script>

  export default {
    name: 'BackButton',

    props: {
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      variant: { type: String, default: 'primary' },
    },

    methods: {
      onClick() {
        this.$emit('click');
      },

      onMousedown() {
        this.$emit('mousedown');
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-button {
    --border-radius: 100px;
    --box-shadow: none;
  }
  .btn-primary {
    --background: #4064c0;
    --background-hover: #181f36;
    width: 110px;
    height: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;

    .text {
      color: white;
    }
  }
</style>

import { createRouter, createWebHistory, createWebHashHistory } from '@ionic/vue-router';

import authRoutes from '@/router/modules/auth';
import locationsRoutes from '@/router/modules/locations';
import settingsRoutes from '@/router/modules/settings';
import guestMiddleware from '@/middleware/guest';


const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    name: 'Welcome',
    beforeEnter: guestMiddleware,
    component: () => import('@/views/Welcome'),
  },
  ...authRoutes,
  ...locationsRoutes,
  ...settingsRoutes
];

const router = createRouter({
  history: window.Cypress ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
});

export default router;

export default (to, from, next) => {
  const hasUser = localStorage.getItem('tithely:user') !== null;
  const hasToken = localStorage.getItem('tithely:access_token') !== null;

  if (hasUser && hasToken) {
      return next('/locations');
  }

  return next();
}

import { people as peopleApi } from '@/api';

const people = {
  namespaced: true,

  state: {
    items: [],
    current: null,
    gradeOptions: [
      { value: 'no-grade', label: 'No Grade' },
      { value: 'graduated', label: 'Graduated' },
      { value: 'pre-school', label: 'Pre School' },
      { value: 'kindergarten', label: 'Kindergarten' },
      { value: '1', label: '1st Grade' },
      { value: '2', label: '2nd Grade' },
      { value: '3', label: '3rd Grade' },
      { value: '4', label: '4th Grade' },
      { value: '5', label: '5th Grade' },
      { value: '6', label: '6th Grade' },
      { value: '7', label: '7th Grade' },
      { value: '8', label: '8th Grade' },
      { value: '9', label: '9th Grade' },
      { value: '10', label: '10th Grade' },
      { value: '11', label: '11th Grade' },
      { value: '12', label: '12th Grade' },
      { value: 'under-grad', label: 'University: Under Graduate' },
      { value: 'post-grad', label: 'University: Post Graduate' },
    ],
  },

  getters: {
    getSchoolGradeLabel: (state) => (grade) => {
      const gradeOption = state.gradeOptions.find(
        (option) => option.value === grade
      );

      return gradeOption ? gradeOption.label : null;
    },
  },

  actions: {
    /**
     * Get all people
     */
    async get({ commit }, payload) {
      try {
        const { data } = await peopleApi.get(
          `/organizations/${payload.organizationId}/people?sort=date_created&sort_order=asc&search=${payload.peopleFilter}&limit=15`
        );

        commit('SET_ITEMS', data.data);

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * Get person details
     */
    async show({ commit }, payload) {
      try {
        const { data } = await peopleApi.get(
          `/organizations/${payload.organizationId}/people/${payload.peopleId}`
        );

        commit('SET_CURRENT', data.data);

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * Store a person
     */
    async store(_, payload) {
      try {
        const { data } = await peopleApi.post(
          `/organizations/${payload.orgId}/people`,
          { data: payload.form }
        );

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload;
    },

    SET_CURRENT(state, payload) {
      state.current = payload;
    },

    CLEAR(state) {
      state.items = [];
      state.current = null;
    },
  },
};

export default people;

<template>
  <ion-button
    expand="block"
    :class="[`btn-menu`]"
    :disabled="disabled"
    @click.stop="onClick"
    @mousedown="onMousedown"
  >
    <div class="social-logo">
      <slot v-if="!loading" name="icon"></slot>
      <span class="button-text">{{ !loading ? text : 'Loading...' }}</span>
    </div>
  </ion-button>
</template>

<script>
  export default {
    name: 'MenuButton',

    props: {
      text: { type: String, default: '' },
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },

    methods: {
      onClick() {
        this.$emit('click');
      },

      onMousedown() {
        this.$emit('mousedown');
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-button {
    width: 600px;
    height: 60px;
    --border-radius: 100px;
    --box-shadow: none;
    text-transform: none;
  }

  .btn-menu {
    height: 50px;
    width: 100vw;
    --background: none;
    --border-radius: 10px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7);
    --background: rgba(24, 31, 54, 1);

    font-size: 17px;
    .button-text {
      margin-left: 15px;
    }
  }
  ion-button.btn-menu:hover {
    height: 50px;
    --background: rgba(33, 42, 74, 1);
    --border-radius: 10px;
    text-align: left;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
  }

  .social-logo {
    width: 100%;
    height: 100%;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
</style>

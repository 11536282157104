const search = {
    namespaced: true,

    state: {
        people: [],
        households: [],
        current_household: null
    },

    actions: {
        async getPeople({ commit }) {
            commit('SET_PEOPLE', []);
        },

        async getHouseholds({ commit }) {
            commit('SET_HOUSEHOLDS', []);
        },

        async showHousehold({ commit }) {
            commit('SET_CURRENT_HOUSEHOLD', {});
        }
    },

    mutations: {
        SET_PEOPLE(state, payload) {
            state.people = payload;
        },

        SET_HOUSEHOLDS(state, payload) {
            state.households = payload;
        },

        SET_CURRENT_HOUSEHOLD(state, payload) {
            state.current_household = payload;
        }
    }
}

export default search;

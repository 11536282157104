<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_936:17127)">
      <path
        d="M8.25 8.95264C8.25 9.9472 8.64509 10.901 9.34835 11.6043C10.0516 12.3075 11.0054 12.7026 12 12.7026C12.9946 12.7026 13.9484 12.3075 14.6517 11.6043C15.3549 10.901 15.75 9.9472 15.75 8.95264C15.75 7.95807 15.3549 7.00425 14.6517 6.30099C13.9484 5.59772 12.9946 5.20264 12 5.20264C11.0054 5.20264 10.0516 5.59772 9.34835 6.30099C8.64509 7.00425 8.25 7.95807 8.25 8.95264Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 1.45264C13.9891 1.45264 15.8968 2.24281 17.3033 3.64934C18.7098 5.05586 19.5 6.96351 19.5 8.95264C19.5 12.3596 14.426 20.9026 12.625 23.6176C12.5566 23.7208 12.4637 23.8054 12.3547 23.8639C12.2456 23.9224 12.1238 23.9531 12 23.9531C11.8762 23.9531 11.7544 23.9224 11.6453 23.8639C11.5363 23.8054 11.4434 23.7208 11.375 23.6176C9.574 20.9026 4.5 12.3596 4.5 8.95264C4.5 6.96351 5.29018 5.05586 6.6967 3.64934C8.10322 2.24281 10.0109 1.45264 12 1.45264V1.45264Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_936:17127">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.702637)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'Navigation',
  };
</script>

import authMiddleware from '@/middleware/auth';

const routes = [
    {
        path: '/settings',
        name: 'Settings',
        beforeEnter: authMiddleware,
        component: () => import('@/views/settings/index'),
      },
      {
        path: '/settings/location',
        name: 'ChangeLocation',
        beforeEnter: authMiddleware,
        component: () => import('@/views/settings/Location'),
      },
  ];
  
  export default routes;
  
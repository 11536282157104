import { auth as authApi, people, checkin, services }  from '@/api';

const auth = {
  namespaced: true,

  state: () => ({
    session_token: null,
    access_token: null,
    user: null,
  }),

  getters: {
    user: (state) => state.user,

    authenticated: (state) => {
      return !!state.user && !!state.session_token;
    },
  },

  actions: {
    /**
     * Authenticate using credentials
     */
    async credentialsLogin({ commit, dispatch }, payload) {
      try {
        const { data } = await authApi.post('/token/password', {
          email: payload.email,
          password: payload.password,
        });

        commit('SET_USER', data.user);
        commit('SET_SESSION_TOKEN', data.token);

        await dispatch('refreshToken');

        return Promise.resolve(data);
      } catch(error) {
        return Promise.reject(error);
      }
    },

    /**
     * Logout action
     */
    logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_ACCESS_TOKEN', null);

      return Promise.resolve(true);
    },

    /**
     * Refresh access token
     */
    async refreshToken({ commit, state }) {
      try {
        const token = state.session_token || localStorage.getItem('tithely:session_token');

        if (! token) {
          throw new Error('Missing session token');
        }

        const { data } = await authApi.post('/access_token', {
          grant_type: 'session_token',
          client_id: 'app',
          session_token: token,
          scope: ['all'],
        });

        commit('SET_ACCESS_TOKEN', data.access_token);

        return Promise.resolve(data.access_token);
      } catch(error) {
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    SET_SESSION_TOKEN(state, session_token) {
      state.session_token = session_token;
      localStorage.setItem('tithely:session_token', session_token);

      if (! session_token) {
        localStorage.removeItem('tithely:session_token');
      }
    },

    SET_ACCESS_TOKEN(state, access_token) {
      state.access_token = access_token;
      localStorage.setItem('tithely:access_token', access_token);

      people.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      services.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      checkin.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      if (! access_token) {
        localStorage.removeItem('tithely:access_token');

        delete people.defaults.headers.common['Authorization'];
        delete services.defaults.headers.common['Authorization'];
        delete checkin.defaults.headers.common['Authorization'];
      }
    },

    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('tithely:user', JSON.stringify(user));

      if (! user) {
        localStorage.removeItem('tithely:user');
        localStorage.removeItem('tithely:session_token');
      }
    },
  },
};

export default auth;

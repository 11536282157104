import authMiddleware from '@/middleware/auth';

const routes = [
  {
    path: '/locations',
    name: 'Locations',
    beforeEnter: authMiddleware,
    component: () => import('@/views/locations'),
  },
  {
    path: '/locations/:id/services',
    name: 'LocationServices',
    beforeEnter: authMiddleware,
    component: () => import('@/views/locations/services'),
  },
  {
    path: '/locations/:locationId/services/:serviceId/search',
    name: 'LocationServiceSearch',
    beforeEnter: authMiddleware,
    component: () => import('@/views/locations/services/search'),
  },
  {
    path: '/locations/:locationId/services/:serviceId/search/household/:householdId',
    name: 'LocationServiceSearchHousehold',
    beforeEnter: authMiddleware,
    component: () => import('@/views/locations/services/search/household'),
  },
  {
    path: '/checkin/success',
    name: 'CheckinSuccess',
    beforeEnter: authMiddleware,
    component: () => import('@/views/checkin/success'),
  }
];

export default routes;

<template>
  <div>
    <ion-menu
      side="start"
      menu-id="menu"
      content-id="main-content"
      class="sidebar"
    >
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <div class="sidebar-title">
            <template v-if="!label && user">
              <Logo class="logo" />
              <span class="title">{{ userOrganizationName }}</span>
            </template>
            <template v-else>
              <span class="title">{{ label }}</span>
            </template>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="list">
          <ion-item v-if="locations.length > 1">
            <MenuButton
              text="Change location"
              variant="menu"
              @click="resetLocation"
            >
              <template #icon>
                <NavigationIcon />
              </template>
            </MenuButton>
          </ion-item>
          <ion-item>
            <MenuButton
              text="Logout"
              variant="menu"
              @click="handleLogout()"
              id="logoutBtnHeader"
            >
              <template #icon>
                <LogoutIcon />
              </template>
            </MenuButton>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-header class="header ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button
            v-if="showMenuButton"
            color="light"
            data-cy="menu-button"
          ></ion-menu-button>
        </ion-buttons>

        <div
          class="navbar-container"
          :class="backButton ? ' button-offset' : ''"
        >
          <ion-item
            v-if="showBackButton"
            class="back-button"
            slot="start"
            lines="none"
          >
            <BackButton @click="onBackButtonClick" />
          </ion-item>
          <template v-if="!label && user">
            <span class="navbar-title">{{ userOrganizationName }}</span>
          </template>
          <template v-else>
            <span class="navbar-title">{{ label }}</span>
          </template>

          <ion-item
            v-if="shouldShowStartOver"
            class="start-over-container"
            lines="none"
          >
            <ion-button
              expand="block"
              shape="round"
              size="default"
              fill="outline"
              @click="
                $router.push({
                  name: 'LocationServices',
                  params: { id: currentLocation.id },
                })
              "
            >
              Start Over
            </ion-button>
          </ion-item>
        </div>
      </ion-toolbar>
    </ion-header>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { IonHeader, IonToolbar, IonButtons, IonMenuButton, menuController } from '@ionic/vue';

  import LogoutIcon from '@/components/shared/icons/Logout.vue';
  import NavigationIcon from '@/components/shared/icons/Navigation.vue';

  import MenuButton from '@/components/shared/buttons/MenuButton';
  import BackButton from '@/components/shared/buttons/BackButton';
  import Logo from '@/components/shared/icons/Logo';

  export default {
    components: {
      LogoutIcon,
      NavigationIcon,
      Logo,
      MenuButton,
      BackButton,
      IonMenuButton,
      IonHeader,
      IonToolbar,
      IonButtons,
    },

    props: {
      backButton: { type: Boolean, default: false },
      backButtonRoute: { type: String, default: null },
      menuButton: { type: Boolean, default: false },
      label: { type: String, default: null },
    },

    computed: {
      ...mapState({
        theme: (state) => state.theme,
        user: (state) => state.auth.user,
        currentLocation: (state) => state.locations.current,
        locations: (state) => state.locations.items,
      }),

      userOrganizationName() {
        return this.userOrganization ? this.userOrganization.name : '';
      },

      userOrganization() {
        return this.user
          ? this.user.organizations.find(
              (organization) =>
                organization.id === this.user.currentOrganizationId
            )
          : null;
      },

      userOrganzationLocations() {
        return this.userOrganization ? this.userOrganization.locations : [];
      },

      currentRouteName() {
        return this.$route.name;
      },

      showBackButton() {
        return (
          this.backButton &&
          !(
            this.currentRouteName === 'LocationServices' &&
            this.userOrganzationLocations.length === 1
          )
        );
      },

      showMenuButton() {
        return (
          this.menuButton ||
          (this.currentRouteName === 'LocationServices' &&
            this.userOrganzationLocations.length === 1)
        );
      },

      shouldShowStartOver() {
        return [
          'LocationServiceSearch',
          'LocationServiceSearchHousehold',
          'CheckinSuccess',
        ].includes(this.$route.name);
      },
    },

    methods: {
      ...mapMutations({
        setCurrentLocation: 'locations/SET_CURRENT',
      }),

      handleLogout() {
        menuController.close();
        this.$router.push({ name: 'Logout' });
      },

      onBackButtonClick() {
        if (!this.backButtonRoute) return this.$router.back();

        return this.$router.push(this.backButtonRoute);
      },

      resetLocation() {
        this.setCurrentLocation(null);
        localStorage.removeItem('tithely:location');

        setTimeout(() => {
          this.$router.push({ name: 'Locations' });
        }, 500);
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-content {
    --background: #181f36;
  }
  ion-header.header {
    ion-toolbar {
      --background: #181f36;
      display: flex;
      --border-style: none;
      ion-menu-button {
        position: absolute;
      }
      height: 80px;

      .navbar-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        position: relative;

        .logo {
          width: 40px;
          height: 40px;
          color: white;
          margin-right: 0.8rem;
        }

        .navbar-title {
          font-size: 16px;
          font-weight: 700;
          color: white;
          margin-left: 0.8rem;
        }

        .back-button {
          position: fixed;
          top: 1rem;
          left: 1rem;
        }

        .start-over-container {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;

          ion-button {
            --border-color: #fff;
            --color: #fff;
            --padding-top: 18px;
            --padding-bottom: 18px;
            text-transform: none;
          }
        }

        @media (max-width: 1024px) {
          .back-button {
            top: 1.6rem !important;
            left: 0.2rem !important;
          }
        }
      }
    }
  }

  ion-header.header-dark {
    ion-toolbar {
      --border-style: none;
      ion-menu-button {
        position: absolute;
        margin-top: 0.8rem;
      }
      --background: #ffffff00;
      height: 80px;

      .navbar-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.8rem;

        .logo {
          width: 32px;
          height: 32px;
          color: white;
          margin-right: 0.8rem;
        }

        .navbar-title {
          font-size: 16px;
          font-weight: 700;
          color: white;
          margin-left: 0.8rem;
        }
      }
    }
  }

  .sidebar {
    --background: #181f36;
    ion-header {
      ion-toolbar {
        padding-left: 15px;
        margin-top: 20px;
        margin-bottom: 10px;
        --background: #181f36;
        color: white;

        .sidebar-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          .logo {
            width: 38px;
            height: 38px;
          }
          .title {
            font-weight: bold;
            font-size: 20px;
            margin-left: 10px;
          }
        }
      }
    }
    ion-item {
      --background: #181f36;
      --inner-border-width: 0px;
      --padding-start: 8px;
      --padding-end: 6px;
      --inner-padding-end: 6px;
    }
  }
</style>

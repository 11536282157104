import guestMiddleware from '@/middleware/guest';

const routes = [
  {
    path: '/auth/login',
    name: 'Login',
    beforeEnter: guestMiddleware,
    component: () => import('@/views/auth/Login'),
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    beforeEnter: guestMiddleware,
    component: () => import('@/views/auth/ForgotPassword'),
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout'),
  },
];

export default routes;

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BaseMixin from './mixins/base';


import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import dayjs from 'dayjs';
import { defineRule } from 'vee-validate';
import { required, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('min', min);

const app = createApp(App).use(IonicVue).use(store).use(router);

app.mixin(BaseMixin);
app.config.globalProperties.$dayjs = dayjs;

if(localStorage.getItem('theme')) {
  store.commit( 'SET_THEME', localStorage.getItem('theme'));
} else {
  store.commit( 'SET_THEME', 'light');
}

store.commit('auth/SET_SESSION_TOKEN', localStorage.getItem('tithely:session_token'));
store.commit('auth/SET_ACCESS_TOKEN', localStorage.getItem('tithely:access_token'));
store.commit('auth/SET_USER', JSON.parse(localStorage.getItem('tithely:user')));
store.commit('locations/SET_CURRENT', JSON.parse(localStorage.getItem('tithely:location')));

router.isReady().then(() => {
  app.mount('#app');
});

import { checkin as checkinApi } from '@/api';

export default {
  namespaced: true,

  state: () => ({
    session: null,
    pendingAttendees: [],
    attendees: [],
  }),

  getters: {
    //
  },

  actions: {
    async fetchSession({ commit }, payload) {
      try {
        const { data } = await checkinApi.get(
          `/organizations/${payload.organizationId}/sessions`,
          {
            params: {
              place_id: payload.serviceId,
              place_type: 'SERVICE',
            },
          }
        );

        if (!data.data.length) {
          return Promise.resolve(false);
        }

        commit('SET_SESSION', data.data[0]);

        return Promise.resolve(data.data[0]);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    /**
     * Start checkin session
     */
    async startSession({ commit }, payload) {
      try {
        const { data } = await checkinApi.post(
          `/organizations/${payload.organizationId}/sessions`,
          {
            data: {
              check_in_start: payload.start,
              check_in_finish: payload.finish,
              place: {
                id: payload.serviceId,
                type: 'SERVICE',
              },
            },
          }
        );

        commit('SET_SESSION', data.data);

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * Checking all pending attendees
     */
    async checkinAllPending(
      { dispatch, state, commit },
      { organizationId, serviceId }
    ) {
      if (!state.pendingAttendees.length) {
        return Promise.resolve(true);
      }

      const errors = [];
      const pendingAttendees = JSON.parse(
        JSON.stringify(state.pendingAttendees)
      );

      for (const person of pendingAttendees) {
        try {
          await dispatch('checkin', {
            organizationId,
            serviceId,
            personId: person.id,
          });

          commit('MOVE_TO_ATTENDEES', person.id);
        } catch (error) {
          errors.push({
            error,
            personId: person.id,
          });
        }
      }

      if (errors.length) {
        return Promise.reject(errors);
      }

      return Promise.resolve(true);
    },
    /**
     * Checkin a single person
     */
    async checkin({ state }, payload) {
      try {
        if (!state.session) {
          throw new Error('The checkin session was not created.');
        }

        await checkinApi.post(
          `/organizations/${payload.organizationId}/sessions/${state.session.id}/attendees`,
          {
            data: {
              subject: {
                id: payload.personId,
                type: 'PERSON',
              },
              place: {
                id: payload.serviceId,
                type: 'SERVICE',
              },
            },
          }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     *
     */
    async fetchSessionAttendees({ commit }, payload) {
      try {
        const { data } = await checkinApi.get(
          `/organizations/${payload.organizationId}/sessions/${payload.sessionId}/attendees`
        );

        if (!data.data.length) {
          return Promise.resolve(true);
        }

        data.data.forEach(attendee => {
          commit('ADD_ATTENDEE', { id: attendee.subject.id });
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    /**
     * Add person to penting attendees array
     */
    ADD_PENDING_ATTENDEE(state, attendee) {
      let index = state.pendingAttendees.findIndex(
        (person) => person.id === attendee.id
      );

      if (index !== -1) {
        return;
      }

      state.pendingAttendees.push(attendee);
    },

    REMOVE_PENDING_ATTENDEE(state, attendee) {
      let index = state.pendingAttendees.findIndex(
        (person) => person.id === attendee.id
      );

      if (index === -1) {
        return;
      }

      state.pendingAttendees.splice(index, 1);
    },

    MOVE_TO_ATTENDEES(state, personId) {
      let index = state.pendingAttendees.findIndex(
        (person) => person.id === personId
      );

      if (index === -1) {
        return;
      }

      const person = Object.assign({}, state.pendingAttendees[index]);

      state.attendees.push(person);
      state.pendingAttendees.splice(index, 1);
    },

    /**
     * Set the active checkin session
     */
    SET_SESSION(state, session) {
      state.session = session;
    },

    /**
     * Add person to attendees array
     */
    ADD_ATTENDEE(state, attendee) {
      let index = state.attendees.findIndex(
        (person) => person.id === attendee.id
      );

      if (index !== -1) {
        return;
      }

      state.attendees.push(attendee);
    },

    /**
     * Set the active checkin attendees
     */
    CLEAR(state) {
      state.session = null;
      state.pendingAttendees = [];
      state.attendees = [];
    },
  },
};

import { createStore } from 'vuex';

import auth from '@/store/modules/auth';
import services from '@/store/modules/services';
import locations from '@/store/modules/locations';
import search from '@/store/modules/search';
import people from '@/store/modules/people';
import households from '@/store/modules/households';
import checkin from '@/store/modules/checkin';

export default createStore({
  state: {
    theme: 'light',
  },

  mutations: {
    SET_THEME(state, theme) {
      state.theme = theme;
    },
  },

  modules: {
    auth,
    services,
    locations,
    search,
    people,
    households,
    checkin,
  },
});

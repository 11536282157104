import axios from 'axios';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_ENDPOINT,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 400) {
      store.dispatch('auth/logout').then(() => {
        router.push({name: 'Login'});
      });
    }

    return Promise.reject(error);
  }
);

export default instance;

import { Capacitor } from '@capacitor/core';
import { toastController } from '@ionic/vue';

export default {
  computed: {
    currentPlatform() {
      if (process.env.IS_ELECTRON) {
        return 'electron';
      }

      return Capacitor.getPlatform();
    },
  },

  methods: {
    async showToast(message, color, duration) {
      const toast = await toastController
        .create({
          color: color || 'primary',
          duration: duration || 3500,
          message,
        });

      toast.present();
    }
  }
};

<template>
  <ion-app>
    <Header :menuButton="showMenuButton" :backButton="showBackButton" :backButtonRoute="backButtonRoute"/>

    <ion-router-outlet />
    
    <div id="modals"></div>
  </ion-app>
</template>

<script>
  import isElectron from 'is-electron';
  import { mapState } from 'vuex';
  import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';
  import { defineComponent } from 'vue';

  import Header from '@/components/layout/Header';

  export default defineComponent({
    name: 'App',
    
    components: {
      IonApp,
      IonRouterOutlet,
      Header
    },

    data() {
      return {
        showUpdateMessage: false,
        showRestartButton: false,
        updateMessage: '',
      };
    },

    computed: {
      ...mapState({
        location: (state) => state.locations.current,
        service: (state) => state.services.current,
      }),

      showMenuButton() {
        switch(this.currentRouteName){
          case "Locations":
            return true;
          case "Settings":
            return true;
          default:
            return false;
        }
      },

      showBackButton() {
        switch(this.currentRouteName) {
          case "Login":
            return true;
          case "LocationServices":
            return true;
          case "LocationServiceSearch":
            return true;
          case "LocationServiceSearchHousehold":
            return true;
          default:
            return false;
        }
      },

      backButtonRoute() {
        switch(this.currentRouteName) {
          case "Login":
            return "/welcome";
          case "LocationServices":
            return "/locations";
          case "LocationServiceSearch":
            return `/locations/${this.location.id}/services`;
          case "LocationServiceSearchHousehold":
            return `/locations/${this.location.id}/services/${this.service.id}/search`;
          default:
            return null;
        }
      },

      currentRouteName() {
        return this.$route.name;
      }
    },

    methods: {
      restartApp() {
        window.ipcRenderer.send('restart_app');
      },

      closeNotification() {
        this.showUpdateMessage = false;
      },

      async openToast() {
        const shownButtons = [
          {
            side: 'start',
            text: 'Close',
            handler: this.closeNotification,
          },
        ];

        if (this.showRestartButton) {
          shownButtons.push({
            text: 'Restart',
            role: 'cancel',
            handler: this.restartApp,
          });
        }

        const toast = await toastController.create({
          header: 'Update manager',
          message: this.updateMessage,
          position: 'bottom',
          buttons: shownButtons,
        });
        await toast.present();
      },
    },

    mounted() {
      if (!window.Cypress && isElectron()) {
        window.ipcRenderer.receive('update_available', () => {
          this.updateMessage = 'A new update is available. Downloading now...';
          this.showUpdateMessage = true;
          this.openToast();
        });

        window.ipcRenderer.receive('update_not_available', () => {
          this.updateMessage =
            'The latest version of Check In is installed on your computer';
          this.showUpdateMessage = true;
          this.openToast();
        });

        window.ipcRenderer.receive('checking_for_updates', () => {
          this.updateMessage = 'Checking for updates';
          this.showUpdateMessage = true;
          this.openToast();
        });

        window.ipcRenderer.receive('update_downloaded', () => {
          this.updateMessage =
            'Update Downloaded. It will be installed on restart. Restart now?';
          this.showUpdateMessage = true;
          this.showRestartButton = true;
          this.openToast();
        });
      }
    }
  });
</script>

<style>
ion-router-outlet {
  margin-top: 4rem;
}

@font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap'), 
  }
</style>
import { checkin } from '@/api';

const locations = {
    namespaced: true,

    state: {
        items: [],
        current: null
    },

    mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload;
        },

        SET_CURRENT(state, payload) {
            state.current = payload;
        },
    },

    actions: {
        async get({ commit }, orgId) {
            const { data } = await checkin.get(`/organizations/${orgId}/locations`);

            commit('SET_ITEMS', data.data);
        },
    }
}

export default locations

import auth from './auth';
import createInstance from './createInstance';

const people = createInstance(process.env.VUE_APP_PEOPLE_ENDPOINT);
const checkin = createInstance(process.env.VUE_APP_CHECKIN_ENDPOINT);
const services = createInstance(process.env.VUE_APP_SERVICES_ENDPOINT);

export {
  auth,
  people,
  checkin,
  services,
};

import { people as peopleApi } from '@/api';

const households = {
  namespaced: true,

  state: {
    items: [],
    current: null,
    query: ''
  },

  getters: {
    members: (state) => {
      if (!state.current) {
        return [];
      }

      return state.current.members || [];
    },
  },

  actions: {
    /**
     * Get all households
     */
    async get({ commit }, payload) {
      try {
        const { data } = await peopleApi.get(
          `/organizations/${payload.organizationId}/households?search=${payload.householdFilter}`
        );

        commit('SET_QUERY', payload.householdFilter);
        commit('SET_ITEMS', data.data);

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * Get household details
     */
    async show({ commit }, payload) {
      try {
        const { data } = await peopleApi.get(
          `/organizations/${payload.organizationId}/households/${payload.householdId}`
        );

        commit('SET_CURRENT', data.data);

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(_, payload) {
      try {
        const { data } = await peopleApi.post(
          `/organizations/${payload.organizationId}/households`,
          { data: payload.form }
        );

        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async addMember(_, payload) {
      try {
        await peopleApi.patch(
          `/organizations/${payload.organizationId}/households/${payload.household.id}`,
          {
            data: {
              id: payload.household.id,
              name: payload.household.name,
              members: [payload.form, ...payload.household.members],
            },
          }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async addMemberToCurrent({ state }, payload) {
      
      try {
        const household = state.current;
        await peopleApi.patch(
          `/organizations/${payload.organizationId}/households/${household.id}`,
          {
            data: {
              id: household.id,
              name: household.name,
              members: [payload.form, ...household.members],
            },
          }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    }

},

  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload;
    },

    SET_CURRENT(state, payload) {
      state.current = payload;
    },

    SET_QUERY(state, payload) {
      state.query = payload;
    },

    CLEAR(state) {
      state.items = [];
      state.current = null;
    },

    CLEAR_CURRENT(state) {
      state.current = null;
    },
  },
};

export default households;

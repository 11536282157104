<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.8586 15.3042C28.8586 21.9171 24.0406 27.5853 17.475 28.6245V17.2881H22.8597V12.9425H17.475V6.42405L16.3885 7.08534L13.7435 8.69132L13.1294 9.0692V12.9425H7.69737V17.2881H13.0821V28.6717C5.71346 27.4908 0.659297 20.5473 1.84017 13.1786C3.02104 5.80999 9.9646 0.755855 17.3332 1.93673C24.0406 2.9759 28.8586 8.69132 28.8586 15.3042Z"
      fill="currentColor"
    />
    <path
      d="M15.3514 0C6.8963 0 0 6.8963 0 15.3514C0 23.8064 6.8963 30.7027 15.3514 30.7027C23.8064 30.7027 30.7027 23.8064 30.7027 15.3514C30.7027 6.8963 23.8064 0 15.3514 0ZM15.3514 29.7108C7.41588 29.7108 0.99198 23.2868 0.99198 15.3514C0.99198 7.41588 7.41588 0.991933 15.3514 0.991933C23.2868 0.991933 29.7107 7.41588 29.7107 15.3514C29.7107 23.2868 23.2868 29.7108 15.3514 29.7108Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: 'Logo',
  };
</script>

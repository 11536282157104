<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 19.4526V20.9526C20.25 21.3505 20.092 21.732 19.8107 22.0133C19.5294 22.2946 19.1478 22.4526 18.75 22.4526H14.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.25 4.45264H18.75C19.1478 4.45264 19.5294 4.61067 19.8107 4.89198C20.092 5.17328 20.25 5.55481 20.25 5.95264V7.45264"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.39 23.9426L1.39004 22.7026C1.21075 22.676 1.04714 22.5854 0.929485 22.4475C0.811829 22.3097 0.748085 22.1338 0.750044 21.9526V4.4226C0.752918 4.25367 0.812743 4.09067 0.919826 3.95999C1.02691 3.82931 1.17498 3.73862 1.34004 3.7026L10.34 1.4826C10.4493 1.45874 10.5625 1.45962 10.6714 1.48518C10.7803 1.51073 10.8821 1.56032 10.9694 1.63031C11.0566 1.7003 11.1271 1.78891 11.1757 1.88966C11.2242 1.99041 11.2496 2.10075 11.25 2.2126V23.2126C11.25 23.3199 11.2266 23.4259 11.1816 23.5233C11.1365 23.6207 11.0707 23.7071 10.9889 23.7766C10.9071 23.846 10.8112 23.8968 10.7078 23.9255C10.6043 23.9542 10.4959 23.96 10.39 23.9426V23.9426Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 10.4526L23.25 13.4526H15"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 16.4526L23.25 13.4526"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.38 12.3225C7.27922 12.3225 7.18256 12.3625 7.1113 12.4338C7.04004 12.5051 7 12.6017 7 12.7025C7.01457 12.7915 7.06029 12.8723 7.12898 12.9307C7.19767 12.989 7.28487 13.0211 7.375 13.0211C7.46513 13.0211 7.55233 12.989 7.62102 12.9307C7.68971 12.8723 7.73543 12.7915 7.75 12.7025C7.75003 12.6034 7.71137 12.5083 7.64226 12.4373C7.57314 12.3663 7.47904 12.3251 7.38 12.3225V12.3225Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  export default {
    name: 'Logout',
  };
</script>
